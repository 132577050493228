import { HexType } from "@hex/common";
import React from "react";

import {
  ComponentIcon,
  EyeClosedIcon,
  ProjectIcon,
  PublishedProjectIcon,
} from "../../icons/CustomIcons.js";

interface ProjectCardTypeIconProps {
  hexType: HexType;
  isPublished?: boolean;
  isUnknownHex?: boolean;
  className?: string;
}

export const ProjectCardTypeIcon = React.memo<ProjectCardTypeIconProps>(
  function ProjectCardTypeIcon({
    className,
    hexType,
    isPublished,
    isUnknownHex = false,
  }) {
    if (isUnknownHex) {
      return <EyeClosedIcon className={className} />;
    }
    if (hexType === HexType.COMPONENT) {
      return <ComponentIcon className={className} />;
    }
    if (hexType === HexType.PROJECT && isPublished) {
      return <PublishedProjectIcon className={className} />;
    }

    return <ProjectIcon className={className} />;
  },
);
